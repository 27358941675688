<template>
  <div class="content md no-padding flex-box vertical">
    <TrainForm ref="addForm" :initial="initial" class="add-form-box"></TrainForm>
    <a-space class="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import TrainForm from "./components/TrainForm.vue";

export default {
  name: "TrainAdd",
  components: {
    TrainForm,
  },
  data() {
    return {
      initial: {
        train_place: "浙江省",
      },
    };
  },
  methods: {
    handleCancel() {
      this.$router.back();
    },
    handleConfirm() {
      this.$refs.addForm.validate().then((form) => {
        this.$axios({
          url: "/admin/volunteer-train-list",
          method: "POST",
          data: form,
        }).then(() => {
          this.$message.success("添加成功");
          this.$router.back();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
</style>
